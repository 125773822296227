export enum ShipmentStatus {
  None = 'none',
  InDelivery = 'in_delivery',
  Delivered = 'delivered',
  Loading = 'loading',
  Completed = 'completed',
}

export type ConnaissementType = 'bl' | 'telex' | 'swb';

export interface ICreateShipmentInvoice {
  date: string;
  doc: string;
  shipment_id: string;
  invoice_type: string;
  who?: string;
}

export interface IShipmentInvoice {
  date: string;
  doc: string;
  id: string;
  type: string;
}

export type InvoiceType =
  | 'invoice'
  | 'proforma'
  | 'provisional'
  | 'final'
  | 'debit_note'
  | 'credit_note';

export interface IShipmentDocuments {
  quality_certificate: string;
  packing_list: string;
  insurance_certificate: string;
  survey_report: string;
  connaissement: string;
}

export interface IShipment {
  id: string;
  shipment_status: ShipmentStatus;
  shipment_number: string;
  quality_certificate: string;
  packing_list: string;
  insurance_certificate: string;
  survey_report: string;
  other_files: string[];
  photos: string[];
  claims: string;
  seller_invoices: IShipmentInvoice[];
  buyer_invoices: IShipmentInvoice[];
  deal_id: string;
  estimated_weight: number;
  final_weight: number;
  eta?: string;
  ordinal_number: number;
  connaissement: string;
  connaissement_type: ConnaissementType;
  fixed_exchange: number;
  final_product_price: number;
  seller_hedge: IHedgingDetails;
  seller_email: string;
  seller_other_files: string[];
  seller_name: string;
  seller_shipment_value: number;
  seller_limit_fixation_date: string;
  buyer_hedge: IHedgingDetails;
  buyer_other_files: string[];
  buyer_limit_fixation_date: string;
  buyer_email: string;
  buyer_name: string;
  buyer_shipment_value: number;
}

export interface IUpdateShipment {
  shipmentId: string;
  data: Partial<IShipment>;
}

export interface IShipmentUser {
  company_hedge: IHedgingDetails;
  id: string;
  deal_id: string;
  shipment_status: ShipmentStatus;
  photos: string[];
  ordinal_number: number;
  shipment_number: string;
  final_weight: number;
  eta: string;
  fixed_exchange: number;
  final_product_price: number;
  estimated_weight: number;
  connaissement_type: string;
  connaissement: string;
  quality_certificate: string;
  packing_list: string;
  insurance_certificate: string;
  survey_report: string;
  other_files: string[];
  company_invoices: IShipmentInvoice[];
  company_limit_fixation_date: string;
  company_other_files: string[];
  shipment_value: number;
}

export interface IHedgingDetails {
  hedge_number: string;
  hedge_type: string;
  hedge_status: string;
  id: string;
}
