import { Box } from '@mui/material';
import { MobileHeader } from '../components/mobileHeader';
import { MobileMenu } from '../components/mobileMenu';
import { ScrapexWidgetMobile } from '../components/widget/mobile';
import { useState } from 'react';
import classes from './styles.module.scss';

interface MobileLayoutProps {
  children: React.ReactNode;
  hideHeader?: boolean;
  preventClose?: boolean;
  onSearch?: (query: string) => void;
}

export const MobileLayout = ({
  children,
  hideHeader,
  preventClose,
  onSearch,
}: MobileLayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    if (preventClose) return;
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    if (preventClose) return;
    setIsMenuOpen(false);
  };

  return (
    <Box className={classes.layout}>
      {!hideHeader && <MobileHeader onMenuClick={handleMenuToggle} onSearch={onSearch} />}
      <ScrapexWidgetMobile />
      <Box className={classes.content}>{children}</Box>
      <MobileMenu open={isMenuOpen} onClose={handleMenuClose} />
    </Box>
  );
};
