import { Box, Button, List } from '@mui/material';
import { useState } from 'react';
import {
  ContractStatusIndicator,
  DataListItem,
  DrawerFooter,
  ScrapexSidebarTextH1,
} from '@common/components';
import { DeleteContractModal } from '../deleteContractModal';
import { calculateEstimatedShipmentWeight } from 'src/accountManager/contract/utlis/calculateEstimatedShipmentWeight';
import { PdfFileSelector } from '../createContractDrawer/pdfFileSelector';
import { updateContractWithFiles } from '../../utlis/updateContract';
import { useGetCurrentContract } from 'src/accountManager/contract/hooks/useGetCurrentContract';
import { formatDate } from '@app/utils';

interface IContractInfoDrawerProps {
  contractId: string;
  onClose: () => void;
}

export const ContractInfoDrawer = ({ onClose, contractId }: IContractInfoDrawerProps) => {
  const { contract, isLoading } = useGetCurrentContract(contractId);

  const [deleteContractOpen, setDeleteContractOpen] = useState(false);
  const handleDeleteContract = () => {
    setDeleteContractOpen(false);
  };

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {!isLoading && contract && (
        <>
          <Box padding="0 40px 100px 40px">
            <ScrapexSidebarTextH1>
              Contract No. {contract.seller_contract_number}
            </ScrapexSidebarTextH1>
            <List sx={{ mb: '20px' }}>
              <DataListItem title="Company Status">
                <ContractStatusIndicator status={contract.deal_status} />
              </DataListItem>
              <DataListItem title="Contract Date*">
                {formatDate(contract.contract_date)}
              </DataListItem>
              <DataListItem title="Product*">{contract.product}</DataListItem>
              <DataListItem title="Metal*">{contract.metal}</DataListItem>
              <DataListItem title="Formula*">{contract.formula}</DataListItem>
              <DataListItem title="Product Weight, mt*">{contract.product_weight}</DataListItem>
              <DataListItem title="Number of shipments*">{contract.total_shipments}</DataListItem>
              <DataListItem title="Estimated Shipment Weight" hideDivider>
                {calculateEstimatedShipmentWeight(
                  contract.product_weight,
                  contract.total_shipments,
                )}
              </DataListItem>
            </List>
            <ScrapexSidebarTextH1>Seller Info</ScrapexSidebarTextH1>
            <List sx={{ mb: '20px' }}>
              <DataListItem title="Seller">{contract?.seller?.name}</DataListItem>
              <DataListItem title="Contract No.">{contract?.seller_contract_number}</DataListItem>
              <DataListItem title="PDF Seller Contract">
                <PdfFileSelector
                  onPdfIdSelect={(pdfId) => {
                    updateContractWithFiles({
                      contractId: contract.id,
                      contractType: 'seller',
                      contractFile: pdfId,
                    });
                  }}
                  fileIdProp={contract.seller_contract}
                />
              </DataListItem>
            </List>
            <ScrapexSidebarTextH1>Buyer Info</ScrapexSidebarTextH1>
            <List>
              <DataListItem title="Buyer">{contract?.buyer?.name}</DataListItem>
              <DataListItem title="Contract No.">{contract?.buyer_contract_number}</DataListItem>

              <DataListItem title="PDF Buyer Contract">
                <PdfFileSelector
                  onPdfIdSelect={(pdfId) => {
                    updateContractWithFiles({
                      contractId: contract.id,
                      contractType: 'buyer',
                      contractFile: pdfId,
                    });
                  }}
                  fileIdProp={contract.buyer_contract}
                />
              </DataListItem>
            </List>
          </Box>
          <DrawerFooter justifyContent="space-between">
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
            <Box>
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: '16px' }}
                onClick={() => setDeleteContractOpen(true)}
              >
                Remove Contract
              </Button>
              <Button variant="outlined">Contract Filled</Button>
            </Box>
          </DrawerFooter>
          <DeleteContractModal
            open={deleteContractOpen}
            onClose={() => setDeleteContractOpen(false)}
            onConfirm={handleDeleteContract}
            contractNumber={contract?.seller_contract_number}
            contractStatus={contract?.deal_status}
            contractId={contract?.id}
          />
        </>
      )}
    </>
  );
};
