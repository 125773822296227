import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Notifications, Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  getHedgeNotificationsThunk,
  readAllHedgeNotificationsThunk,
} from '../../reducer/notifications/thunk';
import { NotificationTabs } from './components/notificationTabs';
import { CustomBadge } from './components/notificationIcon';
import { NotificationTab, useGetNotifications } from '../hooks/useGetNotifications';
import { NotificationTabsContent } from './components/notificationTabs/NotificationTabsContent';
import { NotificationUnreadInfo } from './components/notificationInfo';

export const NotificationCenterMobile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState<NotificationTab>('hedge');
  const [isOpen, setIsOpen] = useState(false);

  const { hedgeNotifications, notificationsHistory, unreadHedgeCount } =
    useGetNotifications(activeTab);

  useEffect(() => {
    dispatch(getHedgeNotificationsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleChange = (event: React.SyntheticEvent<unknown>, newValue: number) => {
    setActiveTab(newValue === 0 ? 'hedge' : 'history');
  };

  const handleReadAllHedge = () => {
    dispatch(readAllHedgeNotificationsThunk());
  };

  const toggleNotificationCenter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{ margin: '8px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={toggleNotificationCenter}
    >
      <IconButton size="large" color="inherit">
        <CustomBadge mr="3px" color="#FF0000" invisible={!unreadHedgeCount}>
          <Notifications color="inherit" />
        </CustomBadge>
      </IconButton>

      <Box display="flex" flexDirection="column" gap={0.5}>
        {!!unreadHedgeCount && (
          <NotificationUnreadInfo bg="#FF0000" quantity={unreadHedgeCount} type="HEDGE" />
        )}
      </Box>
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: '64px',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#fff',
            zIndex: 1300,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              paddingBottom: 'calc(env(safe-area-inset-bottom) + 80px)',
            }}
          >
            <NotificationTabs
              tabValue={activeTab === 'hedge' ? 0 : 1}
              handleChange={handleChange}
              unreadHedgeCount={unreadHedgeCount}
            />

            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <NotificationTabsContent
                tabValue={activeTab === 'hedge' ? 0 : 1}
                hedgeNotifications={hedgeNotifications}
                notificationsHistory={notificationsHistory}
              />
            </Box>

            {activeTab === 'hedge' && hedgeNotifications.length > 0 && (
              <Box
                sx={{
                  p: 2,
                  borderTop: '1px solid #E5E9F2',
                  backgroundColor: '#fff',
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingBottom: 'calc(env(safe-area-inset-bottom) + 16px)',
                  zIndex: 1301,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handleReadAllHedge}
                >
                  MARK ALL AS READ
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
