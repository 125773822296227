import { useEffect, useState } from 'react';
import { getUserHedgeById } from '@app/api/hedging';
import { IHedge } from '@app/types';

export const useGetHedgeDetails = (hedgeId?: string) => {
  const [hedgeDetails, setHedgeDetails] = useState<IHedge | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchHedgeDetails = async () => {
      if (!hedgeId) return;

      try {
        setLoading(true);
        const response = await getUserHedgeById(hedgeId);
        setHedgeDetails(response.data);
      } catch (error) {
        console.error('Error fetching hedge details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHedgeDetails();
  }, [hedgeId]);

  return { hedgeDetails, loading };
};
