import ConfirmationDialog from '@common/components/dialog/confirmationDialog';
import { HedgeType, HedgeTypeStringMap } from '../../helpers';
import { IHedgeShipment } from '@app/types';

interface HedgeRejectModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  hendgeNumber?: string;
  hedgeType?: HedgeType;
  type?: string;
  hedgeShipments: IHedgeShipment[];
}

export const HedgeRejectModal: React.FC<HedgeRejectModalProps> = ({
  open,
  onClose,
  onConfirm,
  hendgeNumber,
  hedgeType = HedgeType.ACM,
  type = 'Offer',
  hedgeShipments,
}) => {
  const title = `Reject ${type} `;
  const subtitleItems = [
    { key: 'Hedge No.', value: hendgeNumber },
    { key: 'Hedge Type:', value: HedgeTypeStringMap[hedgeType as HedgeType] },
    {
      key: 'Shipments Ord. No.',
      value: hedgeShipments.map((shipment) => shipment.ordinal_number).join(', '),
    },
  ];
  const content = `Do you really want to reject the ${type}? This action is irreversible`;

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      onConfirm={onConfirm}
      btnColor="error"
    />
  );
};
