import React from 'react';
import { Box } from '@mui/material';
import { ScrapexBodyText } from '@common/components';
import { NotificationItem } from '../notificationItem';

import { INotification } from '@app/types/api';

interface NotificationTabsProps {
  tabValue: number;
  hedgeNotifications: INotification[];
  notificationsHistory: INotification[];
}

export const NotificationTabsContent: React.FC<NotificationTabsProps> = ({
  tabValue,
  hedgeNotifications,
  notificationsHistory,
}) => {
  const EmptyQueue = () => {
    return (
      <Box m="50% auto" textAlign="center">
        <ScrapexBodyText> Your queue is empty! You are all set</ScrapexBodyText>
      </Box>
    );
  };

  const tabs = [
    {
      content: (
        <>
          {!hedgeNotifications.length ? (
            <EmptyQueue />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  m: '0',
                  alignItems: 'center',
                  p: '0 ',
                }}
              ></Box>
              {hedgeNotifications.map((notification) => (
                <NotificationItem notification={notification} key={notification.id} />
              ))}
            </>
          )}
        </>
      ),
    },

    {
      content: (
        <>
          {notificationsHistory.map((notification) => (
            <NotificationItem notification={notification} key={notification.id} isHistory={true} />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: '90vh',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <Box>{tabs[tabValue].content}</Box>
    </Box>
  );
};
