import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ModalSubtitle, { SubtitleItem } from '../dialogSubtitle';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitleItems?: SubtitleItem[];
  content: string | React.ReactNode;
  onConfirm: () => void;
  btnColor?: 'primary' | 'error';
  buttonText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  subtitleItems = [],
  content,
  onConfirm,
  btnColor = 'primary',
  buttonText = title,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 555,
          width: 555,
          minHeight: 300,
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: '8px', paddingTop: '24px', fontWeight: 700 }}>
        {title}
      </DialogTitle>
      <ModalSubtitle subtitleItems={subtitleItems} />
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={onConfirm} color={btnColor} variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
