import { Box, Typography, IconButton, Button, List } from '@mui/material';
import { ArrowForward, ArrowForwardIos, ChevronRight, EditOutlined } from '@mui/icons-material';
import classes from '../../styles.module.scss';
import { DataListItem } from '@common/components';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { formatDate, renderDateOrNoneInDrawer, renderValueOrNone } from '@app/utils';
import { HedgeType } from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer/helpers';
import { DatePickerButton } from '@common/components/display/datePicker';
import { HedgingStatus } from '@app/types';

interface OrdersContentProps {
  orderType: HedgeType;
  exchangeLevel: string;
  orderDate: string;
  shipmentsCount: number;
  onOrderTypeClick: () => void;
  onExchangeLevelClick: () => void;
  onDateClick: (date: string) => void;
}

export const OrdersContent = ({
  orderType,
  exchangeLevel,
  orderDate,
  shipmentsCount,
  onOrderTypeClick,
  onExchangeLevelClick,
  onDateClick,
}: OrdersContentProps) => {
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);
  const isReadonly = hedge && hedge.hedge_status !== HedgingStatus.None;

  return (
    <>
      <List sx={{ px: '16px', pb: 0 }}>
        {hedge && (
          <>
            <DataListItem title="Order Type">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                onClick={isReadonly ? undefined : onOrderTypeClick}
                sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              >
                {isReadonly ? null : (
                  <IconButton size="small">
                    <EditOutlined fontSize="small" color="primary" />
                  </IconButton>
                )}

                {orderType === HedgeType.TakeProfit ? 'Take Profit (TP)' : 'OLME'}
              </Box>
            </DataListItem>

            {orderType === HedgeType.TakeProfit ? (
              <>
                <DataListItem title="Date">GTC</DataListItem>
                <DataListItem title="TP Exchange level">
                  {exchangeLevel ? (
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      {isReadonly ? null : (
                        <IconButton size="small" onClick={onExchangeLevelClick}>
                          <EditOutlined fontSize="small" color="primary" />
                        </IconButton>
                      )}
                      {exchangeLevel}
                    </Box>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: '170px',
                        height: '36px',
                        lineHeight: 0,
                      }}
                      onClick={onExchangeLevelClick}
                      endIcon={<ChevronRight />}
                    >
                      ADD
                    </Button>
                  )}
                </DataListItem>

                <DataListItem title="Estimated Unit Price, TP, USD/mt">
                  {renderValueOrNone(hedge?.tp_estimated_product_price)}
                </DataListItem>
              </>
            ) : (
              <DataListItem title="Date">
                {isReadonly ? (
                  renderDateOrNoneInDrawer(hedge?.olme_date)
                ) : (
                  <DatePickerButton
                    value={orderDate}
                    onChange={(date) => onDateClick(date || '')}
                    disablePast
                  />
                )}
              </DataListItem>
            )}

            <DataListItem title="Product/Metal">
              {hedge.product}/{hedge.metal}
            </DataListItem>

            <DataListItem title="Formula">{hedge.formula}</DataListItem>

            <DataListItem title="Hedging Shipments">{shipmentsCount}</DataListItem>
          </>
        )}
      </List>
    </>
  );
};
