import { Box } from '@mui/material';
import { IHedgeShipment } from '@app/types';
import { ShipmentCard } from './components/shipmentCard';
import classes from '../../styles.module.scss';

interface ShipmentsListProps {
  shipments: IHedgeShipment[];
}

export const ShipmentsList = ({ shipments }: ShipmentsListProps) => {
  return (
    <Box className={classes.shipments}>
      {[...shipments]
        .sort((a, b) => a.ordinal_number - b.ordinal_number)
        .map((shipment) => (
          <ShipmentCard key={shipment.id} shipment={shipment} />
        ))}
    </Box>
  );
};
