import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import {
  HedgeType,
  HedgeTypeStringMap,
} from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer/helpers';
import classes from './styles.module.scss';
import { IHedgeShipment } from '@app/types';

interface RejectDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  hedgeNo?: string;
  hedgeType?: HedgeType;
  type?: string;
  hedgeShipments: IHedgeShipment[];
}

export const RejectDialog = ({
  open,
  onClose,
  onConfirm,
  hedgeShipments,
  hedgeNo,
  hedgeType = HedgeType.ACM,
  type = 'Offer',
}: RejectDialogProps) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      PaperProps={{ sx: { borderRadius: '24px' } }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6">Reject {type}</Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="var(--fill-text-color)">
            Hedge No. <span>{hedgeNo}</span>
          </Typography>
          <Typography variant="body2" color="var(--fill-text-color)">
            Hedge Type: <span>{HedgeTypeStringMap[hedgeType]}</span>
          </Typography>
          <Typography variant="body2" color="var(--fill-text-color)">
            Shipments Ord. No.{' '}
            <span>{hedgeShipments.map((shipment) => shipment.ordinal_number).join(', ')}</span>
          </Typography>
        </Box>
        <Typography className={classes.message}>
          Do you really want to reject the {type}? This action is irreversible
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, flexDirection: 'column', gap: 1 }}>
        <Button variant="contained" color="error" fullWidth onClick={onConfirm}>
          Reject
        </Button>
        <Button variant="outlined" fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
