import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import classes from './styles.module.scss';

export const ScrapexWidgetMobile = () => {
  const { marketData } = useSelector((state: RootState) => state.account.market);
  const prices = marketData?.prices ?? [];
  const priceData = prices[0] || {};
  const { ask, bid, metal } = priceData;

  return (
    <Box className={classes.widget}>
      <Box className={classes.leftSection}>
        <Box className={classes.row}>
          <Typography variant="body2" color="textSecondary">
            LME (3-month)
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Typography variant="body2">{metal ?? 'Copper'}</Typography>
        </Box>
      </Box>
      <Box className={classes.rightSection}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant="body2" color="textSecondary">
            Bid, USD/mt:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Ask, USD/mt:
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Typography variant="body2">{bid?.toFixed(2) ?? '-'}</Typography>
          <Typography variant="body2">{ask?.toFixed(2) ?? '-'}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
