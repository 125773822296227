import { Box, Typography } from '@mui/material';
import { IHedgeShipment } from '@app/types';
import classes from './styles.module.scss';

interface ShipmentCardProps {
  shipment: IHedgeShipment;
}

export const ShipmentCard = ({ shipment }: ShipmentCardProps) => (
  <Box className={classes.card}>
    <Box className={classes.header}>
      <Box>
        <Typography variant="subtitle2">Ordinal No.{shipment.ordinal_number}</Typography>
        <Typography variant="body2" color="textSecondary">
          Shipment: {shipment.shipment_number || 'None'}
        </Typography>
      </Box>
    </Box>

    <Box className={classes.content}>
      <Box className={classes.row}>
        <Typography variant="body2" color="textSecondary">
          {shipment.final_weight ? 'Final Weight: ' : 'Estimated Weight: '}
        </Typography>
        <Typography variant="body2">
          {shipment.final_weight
            ? `${shipment.final_weight} mt`
            : `${shipment.estimated_weight} mt`}
        </Typography>
      </Box>
    </Box>
  </Box>
);
