import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import { dropActiveHedge, getActiveHedge } from '@api/hedging';
import { getShipmentsThunk } from 'src/account/reducer/shipments/thunks';

export const useDropActiveHedge = (contractId?: string) => {
  const dispatch = useDispatch<AppDispatch>();

  //check and drop active hedge if user refreshed the page/navigated away during waiting a quotation
  const checkAndDropActiveHedge = async () => {
    try {
      const activeHedge = await getActiveHedge();
      if (activeHedge.status === 200) {
        await dropActiveHedge();
        if (contractId) {
          await dispatch(getShipmentsThunk(contractId));
        }
      }
    } catch (error) {
      console.log('Error handling active hedge:', error);
    }
  };

  return { checkAndDropActiveHedge };
};
