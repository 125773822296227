import { Box, Typography, Button } from '@mui/material';
import { MobileLayout } from '@common/components/layout/mobile';
import { useParams } from 'react-router-dom';
import { useGetShipments } from '../components/shipments/components/table/hooks/useGetShipments';
import { ShipmentCard } from './components/shipmentCard';
import { useState, useMemo, useEffect } from 'react';
import classes from './styles.module.scss';
import { HedgeRequest } from './components/hedgeRequest';
import { Loader } from '@common/components';
import { ContractHeader } from './components/contractHeader';
import { useDropActiveHedge } from '../hooks/useDropActiveHedge';

export const ContractMobile = () => {
  const { contractId } = useParams();
  const { shipments, isLoading, error } = useGetShipments(contractId as string);
  const [isHedging, setIsHedging] = useState(false);
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const [showHedgeRequest, setShowHedgeRequest] = useState(false);
  const [selectedHedgeId, setSelectedHedgeId] = useState<string | null>(null);
  const { checkAndDropActiveHedge } = useDropActiveHedge(contractId as string);

  useEffect(() => {
    if (isHedging) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      window.history.pushState({ isHedging: true }, '');

      const handlePopState = (event: PopStateEvent) => {
        event.preventDefault();
        handleCancelHedging();
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [isHedging]);

  useEffect(() => {
    if (showHedgeRequest) {
      window.history.pushState({ showHedgeRequest: true }, '');

      const handlePopState = () => {
        setShowHedgeRequest(false);
        setSelectedShipments([]);
        setSelectedHedgeId(null);
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [showHedgeRequest]);

  useEffect(() => {
    checkAndDropActiveHedge();
  }, []);

  const availableShipments = useMemo(() => {
    if (!shipments) return [];
    return [...shipments]
      .filter(
        (shipment) => !shipment.company_hedge || shipment.company_hedge.hedge_status === 'No hedge',
      )
      .sort((a, b) => a.ordinal_number - b.ordinal_number);
  }, [shipments]);

  const sortedShipments = useMemo(() => {
    if (!shipments) return [];
    return [...shipments].sort((a, b) => a.ordinal_number - b.ordinal_number);
  }, [shipments]);

  const handleStartHedging = () => {
    setIsHedging(true);
    setSelectedShipments([]);
  };

  const handleCancelHedging = () => {
    setIsHedging(false);
    setSelectedShipments([]);
  };

  const handleShipmentSelect = (shipmentId: string) => {
    setSelectedShipments((prev) => {
      if (prev.includes(shipmentId)) {
        return prev.filter((id) => id !== shipmentId);
      }
      return [...prev, shipmentId];
    });
  };

  const handleContinue = () => {
    setIsHedging(false);
    setShowHedgeRequest(true);
  };

  const handleCancelHedgeRequest = () => {
    setShowHedgeRequest(false);
    setSelectedShipments([]);
    setSelectedHedgeId(null);
  };

  const handleViewHedge = (hedgeId: string) => {
    setSelectedHedgeId(hedgeId);
    setShowHedgeRequest(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <MobileLayout>Error: {error.message}</MobileLayout>;
  }

  if (showHedgeRequest) {
    return (
      <Box>
        <HedgeRequest
          selectedShipments={selectedShipments.map((id) => shipments!.find((s) => s.id === id)!)}
          onCancel={handleCancelHedgeRequest}
          hedgeId={selectedHedgeId}
          contractId={contractId}
        />
      </Box>
    );
  }

  return (
    <MobileLayout>
      <Box sx={{ px: 2, pt: 2 }}>
        <ContractHeader contractId={contractId as string} />
      </Box>

      <Box
        sx={{
          flex: 1,
          backgroundColor: 'var(--other-50)',
          minHeight: 0,
        }}
      >
        {!isHedging && (
          <Box sx={{ p: 2 }}>
            <Box className={classes.shipmentsList}>
              {sortedShipments.map((shipment) => (
                <ShipmentCard
                  key={shipment.id}
                  shipment={shipment}
                  ordinalNumber={shipment.ordinal_number}
                  onHedgeClick={
                    shipment.company_hedge
                      ? () => handleViewHedge(shipment.company_hedge.id)
                      : undefined
                  }
                />
              ))}
            </Box>
          </Box>
        )}

        {isHedging && (
          <Box sx={{ p: 2 }}>
            <Box className={classes.hedgingHeader}>
              <Typography variant="subtitle2">
                Available for hedging ({availableShipments.length})
              </Typography>
            </Box>

            <Box className={classes.shipmentsList}>
              {availableShipments.map((shipment) => (
                <ShipmentCard
                  key={shipment.id}
                  shipment={shipment}
                  ordinalNumber={shipment.ordinal_number}
                  selectable
                  selected={selectedShipments.includes(shipment.id)}
                  onSelect={() => handleShipmentSelect(shipment.id)}
                />
              ))}
            </Box>
          </Box>
        )}

        <Box className={classes.footer} sx={{ width: 'auto', px: 2 }}>
          {!isHedging ? (
            <Button variant="contained" color="primary" fullWidth onClick={handleStartHedging}>
              CREATE GROUP HEDGE
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={selectedShipments.length === 0}
                onClick={handleContinue}
              >
                CONTINUE
              </Button>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={handleCancelHedging}
                sx={{ mt: 1 }}
              >
                CANCEL
              </Button>
            </>
          )}
        </Box>
      </Box>
    </MobileLayout>
  );
};
