import { Box, Typography } from '@mui/material';
import { CountdownTimer } from '../timer';

interface TimerChipProps {
  deadline: string;
  onTimerExpired?: () => void;
}

export const TimerChip: React.FC<TimerChipProps> = ({ deadline, onTimerExpired }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        backgroundColor: '#FEF0EF',
        p: '5px 8px',
        borderRadius: '12px',
      }}
    >
      <Typography color="#F44336" fontWeight={500} align="center" fontSize={12}>
        <CountdownTimer deadline={deadline} onTimerExpired={onTimerExpired} />
      </Typography>
    </Box>
  );
};
