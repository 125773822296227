import { Box, Typography } from '@mui/material';
import { HedgingStatus } from '@app/types';
import { HedgingStatusIndicator } from '@common/components';
import { TimerChip } from '@common/components/display/timerChip';
import classes from '../../styles.module.scss';

interface HeaderProps {
  hedgeNumber: string;
  status: HedgingStatus;
  onTimerExpire?: () => void;
  deadline?: string;
}

export const Header: React.FC<HeaderProps> = ({ hedgeNumber, status, onTimerExpire, deadline }) => {
  return (
    <Box className={classes.header}>
      <Box className={classes.headerContent}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="h6">Hedge Request</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <HedgingStatusIndicator status={status} />
            {deadline &&
              (status === HedgingStatus.Waiting || status === HedgingStatus.Quotation) && (
                <TimerChip deadline={deadline} onTimerExpired={onTimerExpire} />
              )}
          </Box>
        </Box>
        <Box className={classes.headerInfo}>
          <Typography variant="body2" color="textSecondary">
            Contract Number:
          </Typography>
          <Typography variant="body2">{hedgeNumber}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
