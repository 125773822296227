import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { getContractsThunk } from 'src/account/reducer/contracts/thunks';

export const useContracts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { contracts, isLoadingContracts: loading } = useSelector(
    (state: RootState) => state.account.contracts,
  );

  useEffect(() => {
    dispatch(getContractsThunk());
  }, []);

  return { contracts, loading };
};
