import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

interface ResponsiveWrapperProps {
  DesktopComponent: JSX.Element;
  MobileComponent: JSX.Element;
}

export const ResponsiveWrapper = ({
  DesktopComponent,
  MobileComponent,
}: ResponsiveWrapperProps) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const checkMobileDevice = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      setIsMobileDevice(isMobile);
    };

    checkMobileDevice();
  }, []);

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  return isMobileDevice ? MobileComponent : isSmallScreen ? MobileComponent : DesktopComponent;
};
