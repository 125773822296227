import { IconButton, Stack, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useState } from 'react';
import { MobileSearch } from '../mobileSearch';
import { useContracts } from 'src/account/contracts/hooks/useContracts';
import classes from './styles.module.scss';
import { NotificationCenter } from 'src/account/notifications/notificationCenter';
import { ToastContainer } from 'react-toastify';
import { NotificationCenterMobile } from 'src/account/notifications/notificationCenterMobile';

interface MobileHeaderProps {
  onMenuClick?: () => void;
  onSearch?: (query: string) => void;
}

export const MobileHeader = ({ onMenuClick, onSearch }: MobileHeaderProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchOpen = () => {
    setIsSearchOpen(true);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
    onSearch?.('');
  };

  return (
    <Box className={classes.headerContainer}>
      {!isSearchOpen ? (
        <div className={classes.mobileHeader}>
          <div className={classes.logo}>
            Scrap<span>EX</span>
          </div>
          <Stack direction="row" spacing={1}>
            <ToastContainer style={{ width: '100%' }} limit={2} autoClose={1500} />
            <NotificationCenterMobile />
            <IconButton color="inherit" onClick={handleSearchOpen}>
              <SearchIcon sx={{ color: 'white' }} />
            </IconButton>
            <IconButton color="inherit" onClick={onMenuClick}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </div>
      ) : (
        <MobileSearch
          onClose={handleSearchClose}
          onSearch={(query) => {
            onSearch?.(query);
          }}
        />
      )}
    </Box>
  );
};
