import { HedgingStatus, IHedge } from '@app/types';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
} from '@common/components';
import { Box, Button, Typography } from '@mui/material';
import { CopyIcon } from '../copyIcon';
import { ArrowForward, DoneAllOutlined } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  setFixedExchangeThunk,
  setQuotationThunk,
  setRejectOrderThunk,
  setRejectWithMessageThunk,
} from 'src/hedgeAdmin/reducer/hedging/thunk';
import { HedgeRejectModal } from '../hedgeRejectModal';
import { HedgeRequestDrawerInfo } from '../hedgeRequestDrawerInfo';
import { useState } from 'react';
import { useGetCurrentHedge } from 'src/hedgeAdmin/hedging/shared/hooks/useGetCurrentHedge';
import { generateBrokerMessage } from 'src/hedgeAdmin/hedging/shared/utils';
import { DotDecimalInput } from '@common/components/display/hedging/input';
import { renderValueOrNone, renderValueOrNoneInDrawer } from '@app/utils';

interface IHedgeRequestDrawerProps {
  hedgeId: string;
  onClose: () => void;
}

export const HedgeRequestDrawerACM = ({ hedgeId, onClose }: IHedgeRequestDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { hedge, loading, error } = useGetCurrentHedge(hedgeId);
  const [hedgeRejectModalOpen, setHedgeRejectModalOpen] = useState(false);
  const [quotation, setQuotation] = useState<string>('');
  const [fixedExchange, setFixedExchange] = useState<string>('');

  const handleChangeQuotation = (value: string) => {
    setQuotation(value);
  };
  const handleChangeFixedExchange = (value: string) => {
    setFixedExchange(value);
  };

  const sendFixedExchange = () => {
    const fixedExchangeValue = parseFloat(fixedExchange);

    dispatch(setFixedExchangeThunk({ fixed_exchange: fixedExchangeValue, hedgeId }));
    onClose();
  };

  const sendQuotation = () => {
    const quotationValue = parseFloat(quotation);

    dispatch(
      setQuotationThunk({
        quotationData: { quotation: quotationValue, timeout: 22 }, //2 seconds timeout buffer
        hedgeId,
      }),
    );
    onClose();
  };

  const handleRejectWithMessage = (message: string = '') => {
    dispatch(setRejectWithMessageThunk({ hedgeId, message }));
    onClose();
  };

  const handleRejectOrder = () => {
    dispatch(setRejectOrderThunk(hedgeId));
    onClose();
  };

  const renderQuotationInput = () => (
    <DataListItem title="Current LME Request, USD/mt">
      <DotDecimalInput
        value={quotation}
        onChange={handleChangeQuotation}
        placeholder="Enter Current LME Request"
      />
    </DataListItem>
  );

  const renderQuotationDisplay = (hedge: IHedge) => (
    <DataListItem title="Current LME Request, USD/mt">
      <Box display="flex" alignItems={'center'} justifyContent={'end'} gap={1}>
        <Typography>{renderValueOrNoneInDrawer(hedge.quotation)}</Typography>
        {hedge.hedge_status === HedgingStatus.Confirmed && (
          <DoneAllOutlined color="success" fontSize="small" />
        )}
      </Box>
    </DataListItem>
  );

  const renderFixedExchangeDisplay = (hedge: IHedge) => (
    <DataListItem title="Fixed LME, USD/mt">{renderValueOrNone(hedge.fixed_exchange)}</DataListItem>
  );

  const renderFixedExchangeInput = () => (
    <DataListItem title="Fixed LME, USD/mt">
      <DotDecimalInput
        value={fixedExchange}
        onChange={handleChangeFixedExchange}
        placeholder="Enter Fixed LME"
      />
    </DataListItem>
  );

  const renderFooterContent = (hedge: IHedge) => {
    switch (hedge.hedge_status) {
      case HedgingStatus.Waiting:
        return (
          <Box display="flex" gap={2}>
            <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
              Reject Order
            </Button>
            <Button
              variant="contained"
              onClick={sendQuotation}
              disabled={parseFloat(quotation) <= 0 || quotation === ''}
              endIcon={<ArrowForward />}
            >
              Send Current LME Request
            </Button>
          </Box>
        );
      case HedgingStatus.Confirmed:
        return (
          <Box display="flex" gap={2}>
            <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
              Reject Order
            </Button>
            <Button
              variant="contained"
              onClick={sendFixedExchange}
              endIcon={<ArrowForward />}
              disabled={parseFloat(fixedExchange) <= 0 || fixedExchange === ''}
            >
              Send Fixed LME
            </Button>
          </Box>
        );
      case HedgingStatus.AdminTimeout:
      case HedgingStatus.UserTimeout:
      case HedgingStatus.UserRejectedQuotation:
        return (
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="error" onClick={handleRejectOrder}>
              Reject Order
            </Button>
          </Box>
        );
      default:
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>;
    }
  };

  return (
    <>
      {loading && <LoadingSkeleton />}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && hedge && (
        <>
          <Box padding="34px 40px 100px 40px">
            <ScrapexSidebarTextH1>Hedge Request</ScrapexSidebarTextH1>
            <ScrapexGrayText>Hedge Number: </ScrapexGrayText>
            {hedge.hedge_number}
            <HedgeRequestDrawerInfo hedge={hedge} />
            <ScrapexSidebarTextH1>At the current market (ACM) </ScrapexSidebarTextH1>
            <DataListItem title="Pure Metal, mt">{hedge.pure_metal}</DataListItem>
            <DataListItem title="Broker's message">
              <Box display="flex">
                <Typography>{generateBrokerMessage(hedge)}</Typography>
                <CopyIcon textToCopy={generateBrokerMessage(hedge)} />
              </Box>
            </DataListItem>
            {hedge.hedge_status === HedgingStatus.Waiting
              ? renderQuotationInput()
              : renderQuotationDisplay(hedge)}
            {hedge.hedge_status === HedgingStatus.Confirmed
              ? renderFixedExchangeInput()
              : renderFixedExchangeDisplay(hedge)}
            <DataListItem title="Final Unit Price, USD/mt">
              {renderValueOrNone(hedge.final_product_price)}
            </DataListItem>
          </Box>
          <DrawerFooter>{renderFooterContent(hedge)}</DrawerFooter>

          <HedgeRejectModal
            open={hedgeRejectModalOpen}
            onClose={() => setHedgeRejectModalOpen(false)}
            onConfirm={handleRejectWithMessage}
            hendgeNumber={hedge.hedge_number}
          />
        </>
      )}
    </>
  );
};
