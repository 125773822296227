import { Box } from '@mui/material';
import { Layout } from '@common/components';
import { ContractsTable } from './components/table';

export const Contracts = () => {
  return (
    <Layout>
      <Box sx={{ p: '20px 40px' }}>
        <ContractsTable />
      </Box>
    </Layout>
  );
};
