import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import {
  getHedgeNotificationsThunk,
  getOtherNotificationsThunk,
  getNotificationsHistoryThunk,
} from 'src/account/reducer/notifications/thunk';

export type NotificationTab = 'hedge' | 'other' | 'history';

export const useGetNotifications = (tab: NotificationTab) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    switch (tab) {
      case 'hedge':
        dispatch(getHedgeNotificationsThunk());
        break;
      case 'other':
        dispatch(getOtherNotificationsThunk());
        break;
      case 'history':
        dispatch(getNotificationsHistoryThunk());
        break;
    }
  }, [dispatch, tab]);

  const { hedgeNotifications, otherNotifications, notificationsHistory } = useSelector(
    (state: RootState) => state.account.notifications,
  );

  const unreadHedgeCount = hedgeNotifications?.length || 0;
  const unreadOtherCount = otherNotifications?.length || 0;

  return {
    hedgeNotifications,
    otherNotifications,
    notificationsHistory,
    unreadHedgeCount,
    unreadOtherCount,
  };
};
