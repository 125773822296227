import { List } from '@mui/material';
import { DataListItem } from '@common/components';
import { renderValueOrNone } from '@app/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

interface ACMContentProps {
  totalWeight?: number;
  shipmentsCount: number;
}

export const ACMContent = ({ shipmentsCount }: ACMContentProps) => {
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);

  return (
    <List sx={{ px: '16px', pb: 0 }}>
      {hedge && (
        <>
          <DataListItem title="Current LME Offer, USD/mt">
            {renderValueOrNone(hedge.quotation)}
          </DataListItem>
          <DataListItem title="Fixed LME, USD/mt">
            {renderValueOrNone(hedge.fixed_exchange)}
          </DataListItem>
          <DataListItem title="Final Unit Price, USD/mt">
            {renderValueOrNone(hedge.final_product_price)}
          </DataListItem>
          <DataListItem title="Product/Metal">
            {hedge.product}/{hedge.metal}
          </DataListItem>
          <DataListItem title="Formula">{hedge.formula}</DataListItem>
          <DataListItem title="Hedging Shipments">{shipmentsCount}</DataListItem>
        </>
      )}
    </List>
  );
};
