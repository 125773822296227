import { useNavigate, useLocation } from 'react-router-dom';
import classes from './styles.module.scss';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

export interface ISidebarNavigationItem {
  title: string;
  link: string;
  isActive?: boolean;
  icon: ReactNode;
  highlight?: boolean;
  onClose?: () => void;
}

export const SidebarNavigationItem = ({
  title,
  link,
  isActive,
  icon,
  highlight = false,
  onClose,
}: ISidebarNavigationItem) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const targetPath = '/' + link;
    if (location.pathname === targetPath) {
      onClose?.();
    } else {
      navigate(targetPath);
    }
  };

  return (
    <li
      className={`${classes.item} ${isActive ? classes.activeItem : ''} ${
        highlight ? classes.highlightItem : ''
      }`}
    >
      <button onClick={handleClick} type="button">
        <Box mr={4}>{icon}</Box>
        {title}
      </button>
    </li>
  );
};
