import {
  createHedge,
  getActiveHedge,
  getUserHedgeById,
  getUserHedgeHistory,
  rejectOrder,
  setAnswerToQuotation,
} from '@api/hedging';
import { ICreateHedge, ISetQuotationAnswer } from '@app/types/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateShipmentsWithHedgeThunk } from '../shipments/thunks';
import { RootState } from '@app/store';
import { actions } from './slice';

export const initHedgeThunk = createAsyncThunk(
  'hedging/initHedge',
  async (initHedgeData: ICreateHedge) => {
    try {
      const res = await createHedge(initHedgeData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
export const createHedgeThunk = createAsyncThunk(
  'hedging/createHedge',
  async (requestData: ICreateHedge, thunkAPI) => {
    try {
      const res = await createHedge(requestData);

      const serverTime = res.headers['server-time'];

      if (serverTime) {
        thunkAPI.dispatch(actions.setServerTime(serverTime));
      }

      const shipmentIds = res.data.hedge_shipments.map((shipment) => shipment.id as string);
      shipmentIds.forEach((shipmentId) => {
        thunkAPI.dispatch(updateShipmentsWithHedgeThunk(shipmentId));
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getActiveHedgeThunk = createAsyncThunk('hedging/getActiveHedge', async (thunkAPI) => {
  try {
    const res = await getActiveHedge();

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getHedgeByIdThunk = createAsyncThunk(
  'hedging/getHedgeById',
  async (hedgeId: string, thunkAPI) => {
    try {
      const res = await getUserHedgeById(hedgeId);
      const shipmentIds = res.data.hedge_shipments.map((shipment) => shipment.id as string);
      shipmentIds.forEach((shipmentId) => {
        thunkAPI.dispatch(updateShipmentsWithHedgeThunk(shipmentId));
      });
      //preventing the update of the current hedge state if another hedge action was triggered
      const currentHedgeId = (thunkAPI.getState() as RootState).account.hedging.currentHedge?.id;
      if (currentHedgeId === hedgeId || !currentHedgeId) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const getQuotationThunk = createAsyncThunk(
  'hedging/getQuotationThunk',
  async (_, thunkAPI) => {
    try {
      const res = await getActiveHedge();

      const serverTime = res.headers['server-time'];

      if (serverTime) {
        thunkAPI.dispatch(actions.setServerTime(serverTime));
      }

      const shipmentIds = res.data.hedge_shipments.map((shipment) => shipment.id as string);
      shipmentIds.forEach((shipmentId) => {
        thunkAPI.dispatch(updateShipmentsWithHedgeThunk(shipmentId));
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const setAnswerToQuotationThunk = createAsyncThunk(
  'hedging/setAnswerToQuotationThunk',
  async (answerToQuotation: ISetQuotationAnswer, thunkAPI) => {
    try {
      const res = await setAnswerToQuotation(answerToQuotation);
      const shipmentIds = res.data.hedge_shipments.map((shipment) => shipment.id as string);
      shipmentIds.forEach((shipmentId) => {
        thunkAPI.dispatch(updateShipmentsWithHedgeThunk(shipmentId));
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
export const rejectOrderThunk = createAsyncThunk(
  'hedging/rejectOrderThunk',
  async (hedgeId: string, thunkAPI) => {
    try {
      const res = await rejectOrder(hedgeId);
      const shipmentIds = res.data.hedge_shipments.map((shipment) => shipment.id as string);
      shipmentIds.forEach((shipmentId) => {
        thunkAPI.dispatch(updateShipmentsWithHedgeThunk(shipmentId));
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getHedgeHistoryThunk = createAsyncThunk('hedging/getHedgeHistory', async () => {
  try {
    const res = await getUserHedgeHistory();
    return { hedges: res.data };
  } catch (error) {
    console.log(error);
  }
});
