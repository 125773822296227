import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItem,
  SxProps,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { KeyboardArrowRight, ExpandMore } from '@mui/icons-material';

interface DataListProps {
  children: ReactNode;
  input?: ReactNode;
  title: string;
  subtitle?: ReactNode;
  alignChildren?: 'start' | 'end';
  hideDivider?: boolean;
  sx?: SxProps;
}

export const DataListItem = ({
  children,
  title,
  alignChildren = 'end',
  hideDivider = false,
  subtitle,
  sx,
}: DataListProps) => {
  return (
    <>
      <ListItem sx={{ padding: '16px 0' }}>
        <Grid container alignItems="center">
          <Grid item xs={6} sx={sx}>
            <Box>
              <Typography sx={{ color: 'var(--fill-text-color)' }}>{title}</Typography>
              {subtitle}
            </Box>
          </Grid>
          <Grid sx={{ textAlign: alignChildren }} item xs={6}>
            {children}
          </Grid>
        </Grid>
      </ListItem>
      {!hideDivider && <Divider sx={{ borderColor: 'var(--light-white-color)' }} />}
    </>
  );
};

export const CollapsableDataListItem = ({
  children,
  input,
  title,
  alignChildren = 'end',
  hideDivider = true,
  subtitle,
}: DataListProps) => {
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem sx={{ padding: '16px 0' }}>
        <Grid container alignItems="center">
          <Grid item xs={0.7}>
            <IconButton onClick={toggleCollapse} sx={{ padding: 0 }}>
              {open ? <ExpandMore /> : <KeyboardArrowRight />}
            </IconButton>
          </Grid>
          <Grid item xs={5.3}>
            <Typography sx={{ color: 'var(--fill-text-color)' }}>{title}</Typography>
            {subtitle}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: alignChildren }}>
            {input}
          </Grid>
        </Grid>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
      {!hideDivider && <Divider sx={{ borderColor: 'var(--light-white-color)' }} />}
    </>
  );
};
