import { Box, Button, IconButton } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';
import { useState } from 'react';
import { formatDate } from '@app/utils';

interface DatePickerButtonProps {
  value: string | null;
  onChange: (date: string | null) => void;
  disablePast?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const DatePickerButton = ({
  value,
  onChange,
  disablePast = false,
}: DatePickerButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedDate = newValue?.format('YYYY-MM-DD');
      onChange(formattedDate);
    }
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onClose={() => setOpen(false)}
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        disablePast={disablePast}
        slots={{
          textField: () => (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
              startIcon={<CalendarMonth />}
              sx={{
                width: '170px',
                height: '36px',
                lineHeight: 0,
              }}
            >
              {value ? formatDate(value) : 'PICK A DATE'}
            </Button>
          ),
        }}
      />
    </LocalizationProvider>
  );
};
