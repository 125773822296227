import { configureStore } from '@reduxjs/toolkit';
import { reducer as auth } from '../auth/reducer';
import { reducer as accountManager } from '../accountManager/reducer';
import { reducer as hedgingAdmin } from '../hedgeAdmin/reducer';
import { reducer as account } from '../account/reducer';
import { reducer as socket } from './socket/reducer/';
import { socketMiddleware } from './middleware/socket/socketMiddleWare';

export const store = configureStore({
  reducer: {
    auth,
    accountManager,
    hedgingAdmin,
    account,
    socket,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
